<template>
  <div class="chenggong index">
    <BlackHeader></BlackHeader>
    <div class="toubu">
      <div class="text">
        典型案例 值得信赖
        <div class="bottom">
          积累了2000+的项目成功案例，让客户拥有更多的选择和成功的体验
        </div>
      </div>
    </div>

    <div class="nav-box" ref="navbox">
      <div class="nav" ref="nav">
        <div
          class="nav-son"
          v-for="(item, index) in nav"
          :key="index"
          :class="type == item.type ? 'nav-active' : ''"
          @click="change(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="box">
      <div class="son" v-for="(item, index) in newArr" :key="index">
        <div class="left">
          <div class="title">{{ item.title }}</div>
          <div class="text">
            {{
              item.text.length > 130
                ? item.text.slice(0, 127) + "..."
                : item.text
            }}
          </div>
          <div class="line"></div>
          <div class="gengduo" @click="goto(item)">
            了解更多<span class="el-icon-right"></span>
          </div>
        </div>
        <div class="right">
          <img class="img" :src="item.src" alt="" @click="goto(item)" />
        </div>
      </div>

      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        class="anlibar"
        @current-change="pageChange"
        :current-page.sync="currentPage"
      >
      </el-pagination>
    </div>

    <Footer></Footer>

    <div class="toback" ref="toback">
      <div class="toback-lianxi">
        <div class="toback-ph">
          <div class="toback-sons">
            <div class="toback-barss">
              <div class="toback-top">咨询电话</div>
              <div class="toback-bottom">133-3098-1239</div>
            </div>
            <div class="toback-barss">
              <div class="toback-top">技术支持</div>
              <div class="toback-bottom">189-8005-3442</div>
            </div>
            <div class="toback-barss">
              <div class="toback-top">售后电话</div>
              <div class="toback-bottom">400-8878-352</div>
            </div>
          </div>
        </div>
        <div class="toback-wx">
          <div class="toback-son">
            <img src="@/assets/imgs/home/gongzonghao.png" alt />
            <div class="toback-tip">(成都汉康微信)</div>
          </div>
        </div>
      </div>
      <div class="toback-up" @click="up"></div>
    </div>
  </div>
</template>

<script>
import BlackHeader from "../../components/BlackHeader.vue";
import Footer from "../../components/Footer.vue";
export default {
  components: {
    BlackHeader,
    Footer,
  },
  data() {
    return {
      type: 0,
      list: [
        {
          title: "成都市智慧水务六水数据信息平台",
          text: "以成都市城市大脑为基础，通过若干控制性重点水务信息化项目的建设整合和完善现有信息系统功能，全面接入以“六水”为代表的各业务功能打造全域感知的泛在感知系统，全量汇聚水务数据，强化数据挖掘在山洪预测、管网优化、水资源调度等领域的应用，实现全域感知（水质、水量等）、动态监测（各种水务设备和水利设施）、高效应用（多屏展示、GIS呈现等）的智慧水务平台。把具体业务系统分为水资源支撑模块、水安全保障模块、水净化提升模块、水生态修复模块、水管理创新模块及水文化传承模块，从而打造具有成都特色的业务系统。综合单位实际情况及工作的特殊性专门设计，使系统不仅在当前处于领先地位，而且能为今后的发展提供数据支撑。在功能设计、软件操作以及其它方面设身处地为用户着想，以用户为中心，设计以实用性为第一目的的系统。 采用面向用户的设计风格，设计满足人性化操作方式的展示系统。",
          src: require("../../assets/imgs/anli/liushui (2).png"),
          imgs: [
            { src: require("../../assets/imgs/anli/liushui (2).png") },
            { src: require("../../assets/imgs/anli/liushui (1).png") },
            { src: require("../../assets/imgs/anli/liushui (3).png") },
            { src: require("../../assets/imgs/anli/liushui (4).png") },
            { src: require("../../assets/imgs/anli/liushui (5).png") },
            { src: require("../../assets/imgs/anli/liushui (6).png") },
            { src: require("../../assets/imgs/anli/liushui (7).png") },
            { src: require("../../assets/imgs/anli/liushui (8).png") },
            { src: require("../../assets/imgs/anli/liushui (9).png") },
            { src: require("../../assets/imgs/anli/liushui (10).png") },
          ],
          kehu: "成都市水务局",
          city: "四川省成都市",
          type: 0,
        },
        {
          title:
            "成都市基层防汛监测预警体系-智能预警平台建设项目系统软硬件及系统集成标段",
          text: "本项目建设内容由成都市中心城区暴雨内涝预报预警系统（城市自动监测站网、城市洪涝监测预警平台）、山洪灾害监测预警平台（山洪灾害监测预警软件系统、山洪监测预警设施、）、南河流域洪水预报预警与实时风险分析系统（河道水文监测预警设施、河道断面补测、重点河段及城镇无人机航测与三维场景模型、实时洪水预警预报与风险分析模型构建、南河示范区模型率定验证与数据更新、洪水预报预警与实时分析系统平台）等构成。",
          src: require("../../assets/imgs/anli/jicheng (1).png"),
          kehu: "成都市水务局",
          city: "四川省成都市",
          type: 0,
          imgs: [
            { src: require("../../assets/imgs/anli/jicheng (1).png") },
            { src: require("../../assets/imgs/anli/jicheng (2).png") },
            { src: require("../../assets/imgs/anli/jicheng (3).png") },
            { src: require("../../assets/imgs/anli/jicheng (4).png") },
            { src: require("../../assets/imgs/anli/jicheng (5).png") },
            { src: require("../../assets/imgs/anli/jicheng (6).png") },
          ],
        },
        {
          title: "成都市供水数据接入与实时监测系统",
          text: "经开水务利用新一代的GIS技术，构建高效、合理、实用的供水管网信息系统，为水司各项工作开展及后续信息化系统建设提供数据支撑，进而应用至巡检、抢维修等业务中，辅助各部分工作与管网数据进行紧密结合，从而实现更加精细化的管理，实现各类业务流程、分析结果、运营信息基于地图的可视化展现，辅助水司进行宏观决策、综合运营，实现城市供水管网智慧运作，最终实现龙泉驿自来水公司“智慧水务”的发展目标。",
          src: require("../../assets/imgs/anli/gongshui-1.png"),
          imgs: [
            { src: require("../../assets/imgs/anli/gongshui-1.png") },
            { src: require("../../assets/imgs/anli/gongshui-2.png") },
            { src: require("../../assets/imgs/anli/gongshui-3.png") },
            { src: require("../../assets/imgs/anli/gongshui-4.png") },
            { src: require("../../assets/imgs/anli/gongshui-5.png") },
          ],
          kehu: "经开水务",
          city: "四川省成都市",
          type: 0,
        },
        // {
        //   title:
        //     "成都市中心城区排水管网数字化管理系统建设（软、硬件系统集成部分）(第二次）",
        //   text: "本工程建设内容由设备及系统软件采购、装修、冷通道数据中心、环境监控系统、机房消防系统、新风排风系统、门禁系统、防雷接地系统、综合布线系统、会议室装修及家具、数字音响系统、综合受理中心平台、墙面、系统集成服务构成。",
        //   src: require("../../assets/imgs/anli/test.png"),
        //   kehu: "成都市水务局",
        //   city: "四川省成都市",
        //   type: 0,
        // },
        {
          title: "成都市中心城区排水管网数字化管理系统建设(应用系统平台开发)",
          text: "建设内容由综合信息展示系统、业务应用系统、排水GIS服务平台和排水系统数据库建设等构成验。",
          src: require("../../assets/imgs/anli/paishui (1).png"),
          kehu: "成都市水务局",
          city: "四川省成都市",
          type: 0,
          imgs: [
            { src: require("../../assets/imgs/anli/paishui (1).png") },
            { src: require("../../assets/imgs/anli/paishui (2).png") },
            { src: require("../../assets/imgs/anli/paishui (3).png") },
            { src: require("../../assets/imgs/anli/paishui (4).png") },
            { src: require("../../assets/imgs/anli/paishui (5).png") },
          ],
        },
        {
          title: "锦江绿道府河BIM信息系统",
          text: "以成都地形图为基础，通过3DBIM渲染技术数字还原整个26公里河道，让用户在线体验锦江绿道的真实魅力。同时也让管理者通过绿道的监测信息，实时监测河道点位状态，做好及时防范。同时，在系统上叠加相关河段的巡查信息，便于直观掌握26公里绿道日常运维情况。",
          src: require("../../assets/imgs/anli/bim (1).png"),
          imgs: [
            { src: require("../../assets/imgs/anli/bim (1).png") },
            { src: require("../../assets/imgs/anli/bim (2).png") },
            { src: require("../../assets/imgs/anli/bim (3).png") },
            { src: require("../../assets/imgs/anli/bim (4).png") },
            { src: require("../../assets/imgs/anli/bim (5).png") },
            { src: require("../../assets/imgs/anli/bim (6).png") },
            { src: require("../../assets/imgs/anli/bim (7).png") },
            { src: require("../../assets/imgs/anli/bim (8).png") },
            { src: require("../../assets/imgs/anli/bim (9).png") },
            { src: require("../../assets/imgs/anli/bim (10).png") },
            { src: require("../../assets/imgs/anli/bim (11).png") },
            { src: require("../../assets/imgs/anli/bim (12).png") },
            { src: require("../../assets/imgs/anli/bim (13).png") },
            { src: require("../../assets/imgs/anli/bim (14).png") },
          ],
          kehu: "成都市水务局",
          city: "四川省成都市",
          type: 0,
        },

        {
          title: "成都市山洪灾害预测预警系统建设项目",
          text: "本工程建设内容建设成都市山洪灾害预测水雨情监测站网平台。",
          src: require("../../assets/imgs/anli/sanhong.png"),
          kehu: "成都市水务局",
          city: "四川省成都市",
          type: 0,
          imgs: [{ src: require("../../assets/imgs/anli/sanhong.png") }],
        },

        // {
        //   title:
        //     "四川省（崇州市、彭州市、都江堰市、新津县、大邑县、双流县、龙泉驿区、青白江区）山洪灾害防治县级非工程措施建设采购项目",
        //   text: "本工程建设内容由山洪灾害普查、危险区的划定、临界雨量和水位等预警指标的确定、县级预警平台中心、乡镇预警发布平台、前端监测站点和群测群防构成。",
        //   src: require("../../assets/imgs/anli/test.png"),
        //   kehu: "成都市水务局",
        //   city: "四川省成都市",
        //   type: 0,
        // },
        // {
        //   title: "阿坝州新增县山洪灾害防治县级非工程措施建设采购项目",
        //   text: "本工程建设内容由山洪灾害普查、危险区的划定、临界雨量和水位等预警指标的确定、县级预警平台中心、乡镇预警发布平台、前端监测站点和群测群防构成。",
        //   src: require("../../assets/imgs/anli/test.png"),
        //   kehu: "阿坝州水务局",
        //   city: "四川省阿坝州",
        //   type: 0,
        // },
        // {
        //   title: "甘孜藏族自治州2012年度山洪灾害防治县级非工程措施建设项目",
        //   text: "本工程建设内容由山洪灾害普查、危险区的划定、临界雨量和水位等预警指标的确定、县级预警平台中心、乡镇预警发布平台、前端监测站点和群测群防构成。",
        //   src: require("../../assets/imgs/anli/test.png"),
        //   kehu: "甘孜州水务局",
        //   city: "四川省甘孜州",
        //   type: 0,
        // },

        {
          title:
            "绵竹市国土资源局地质灾害自动化监测预警系统建设设施设备采购项目工程",
          text: "本工程建设内容由山洪灾害普查、危险区的划定、临界雨量和水位等预警指标的确定、县级预警平台中心、乡镇预警发布平台、前端监测站点和群测群防构成。",
          src: require("../../assets/imgs/anli/mianzu.png"),
          kehu: "绵竹国土局",
          city: "四川省绵竹市",
          type: 1,
          imgs: [{ src: require("../../assets/imgs/anli/mianzu.png") }],
        },
        {
          title:
            "四川地质环境监测总站三大片区典型泥石流监测预警示范点建设系统设备采购项目",
          text: "本工程建设内容由山洪灾害普查、危险区的划定、临界雨量和水位等预警指标的确定、县级预警平台中心、乡镇预警发布平台、前端监测站点和群测群防构成。",
          src: require("../../assets/imgs/anli/sanda.png"),
          kehu: "四川省国土厅",
          city: "四川省",
          type: 1,
          imgs: [
            { src: require("../../assets/imgs/anli/sanda.png") },
            { src: require("../../assets/imgs/anli/sanda-1.png") },
            { src: require("../../assets/imgs/anli/sanda-2.png") },
            { src: require("../../assets/imgs/anli/sanda-3.png") },
          ],
        },
        // {
        //   title:
        //     "内蒙古自治区2012年度旗县级山洪灾害防治非工程措施建设项目（西部区）第三标段",
        //   text: "本工程建设内容由山洪灾害普查、危险区的划定、临界雨量和水位等预警指标的确定、县级预警平台中心、乡镇预警发布平台、前端监测站点和群测群防构成。",
        //   src: require("../../assets/imgs/anli/test.png"),
        //   kehu: "内蒙古水利厅",
        //   city: "内蒙古",
        //   type: 0,
        // },
        // {
        //   title: "甘孜藏族自治州2011年度山洪灾害防治县级非工程措施建设项目",
        //   text: "本工程建设内容由山洪灾害普查、危险区的划定、临界雨量和水位等预警指标的确定、县级预警平台中心、乡镇预警发布平台、前端监测站点和群测群防构成。",
        //   src: require("../../assets/imgs/anli/test.png"),
        //   kehu: "甘孜州水务局",
        //   city: "四川省甘孜州",
        //   type: 0,
        // },

        // {
        //   title: "甘孜州2012-2013年中小河流水文监测系统建设项目施工一标段",
        //   text: "本工程建设内容由建筑工程、采集仪器设备安装工程、水文站建设构成；",
        //   src: require("../../assets/imgs/anli/test.png"),
        //   kehu: "甘孜州水文局",
        //   city: "四川省阿坝州",
        //   type: 0,
        // },
        // {
        //   title: "眉山市2012-2013年中小河流水文监测系统建设项目",
        //   text: "本工程建设内容由建筑工程、采集仪器设备安装工程、水文站建设构成；",
        //   src: require("../../assets/imgs/anli/test.png"),
        //   kehu: "眉山水文水资源局",
        //   city: "四川省眉山市",
        //   type: 0,
        // },

        // {
        //   title: "达州市2011年中小河流水文监测系统建设项目",
        //   text: "本工程建设内容由建筑工程、采集仪器设备安装工程、水文站建设构成；",
        //   src: require("../../assets/imgs/anli/test.png"),
        //   kehu: "达州水文水资源局",
        //   city: "四川省达州市",
        //   type: 0,
        // },

        {
          title:
            "霍山县智慧城市建设基础项目城市运营指挥中心、城市公共基础数据中心、城市公共信息平台项目",
          text: "本工程建设内容由城市运营指挥中心（城市运行管理平台：事件管理与应急指挥系统、实时监测与决策支持系统；应用指挥中心基础设施建设；）、公共基础数据中心（基础数据平台：各委办局的数据采集与清晰比对，统一标准格式入库，形成四大库的建设；云数据中心基础设施建设；）、公共信息平台（数据交换共享平台、信息门户、手机APP与微信门户）、标准规范、安全体系建设等构成。",
          src: require("../../assets/imgs/anli/huoshan.png"),
          kehu: "霍山县人民政府",
          city: "安徽省霍山县",
          type: 2,
          imgs: [
            { src: require("../../assets/imgs/anli/huoshan.png") },
            { src: require("../../assets/imgs/anli/huoshan-1.png") },
            { src: require("../../assets/imgs/anli/huoshan-2.png") },
            { src: require("../../assets/imgs/anli/huoshan-3.png") },
          ],
        },
        {
          title:
            "成都市智能交通空间地理信息基础支撑及服务系统项目系统软硬件与系统集成",
          text: "本项目建设内容由标准规范建设、智能交通管控底图融合配图、道路渠化建设、智能交通管控模型建设、智能交通专题数据建设、应用系统建设、基础设施建设等构成；我公司负责标准规范建设、智能交通管控底图融合配图、智能交通管控模型建设、智能交通专题数据建设、应用系统建设、基础设施建设等工作，合同金额占2259.3127万元。",
          src: require("../../assets/imgs/anli/jiaotong.png"),
          kehu: "成都交投",
          city: "四川省成都市",
          type: 2,
          imgs: [{ src: require("../../assets/imgs/anli/jiaotong.png") }],
        },
        {
          title: "联网报警升级改造信息化工程项目施工",
          text: "本项目建设内容由指挥大厅音视频系统、决策大厅音视频系统、小会议室音视频系统、各功能房指挥席位、操作席位及办公家具、UPS机房及电源配电系统、音控室设备、数据中心机房系统、辅助配套设备、指挥中心功能性装修、指挥中心空调系统、指挥中心消防系统等组成。",
          src: require("../../assets/imgs/anli/lianwang.jpg"),
          kehu: "中融安保",
          city: "四川省成都市",
          type: 2,
          imgs: [
            { src: require("../../assets/imgs/anli/lianwang.jpg") },
            { src: require("../../assets/imgs/anli/lianwang-1.jpg") },
            { src: require("../../assets/imgs/anli/lianwang-2.jpg") },
          ],
        },

        // {
        //   title: "泸州市网格化微站和激光雷达项目",
        //   text: "本项目建设内容包括大气网格化监测微站建设及相关服务、大气环境立体走航监测服务、大气网格化监测管控平台建设及相关服务、数据分析及污染溯源系统及相关服务、数据标准与对接、专业运维服务等构成。",
        //   src: require("../../assets/imgs/anli/test.png"),
        //   kehu: "泸州市环保局",
        //   city: "四川省泸州市",
        //   type: 2,
        // },
        {
          title: "贵港市平南县智慧政务综合平台项目",
          text: "本项目建设内容由基础平台、基础服务组件、数据资源管理系统、事项管理系统、电子证照管理系统、一窗式行政审批系统、跨层级审批系统、互联网+政务服务微信平台、数据交叉验证及保全服务、短信服务子系统、监督管理系统、绩效考评系统、领导决策分析系统、移动审批办公平台、投资项目库、智能审批系统、即时交流系统、智能表单系统、政务信用管理系统、关于优化营商环境7个重点指标应用设计等构成。",
          src: require("../../assets/imgs/anli/guigang.png"),
          kehu: "平南县人民政府",
          city: "广西平南县",
          type: 3,
          imgs: [
            { src: require("../../assets/imgs/anli/guigang.png") },
            { src: require("../../assets/imgs/anli/guigang-1.png") },
            { src: require("../../assets/imgs/anli/guigang-2.png") },
          ],
        },
        {
          title: "贵港市覃塘区智慧整合综合平台项目",
          text: "本项目建设内容由基础平台、基础服务组件、数据资源管理系统、事项管理系统、电子证照管理系统、一窗式行政审批系统、跨层级审批系统、互联网+政务服务微信平台、数据交叉验证及保全服务、短信服务子系统、监督管理系统、绩效考评系统、领导决策分析系统、移动审批办公平台、投资项目库、智能审批系统、即时交流系统、智能表单系统、政务信用管理系统、关于优化营商环境7个重点指标应用设计等构成。",
          src: require("../../assets/imgs/anli/guigang.png"),
          kehu: "覃塘区人民政府",
          city: "广西覃塘区",
          type: 3,
          imgs: [
            { src: require("../../assets/imgs/anli/guigang.png") },
            { src: require("../../assets/imgs/anli/guigang-1.png") },
            { src: require("../../assets/imgs/anli/guigang-2.png") },
          ],
        },
        {
          title: "贵港市港南区智慧政务综合平台项目",
          text: "本项目建设内容由基础平台、基础服务组件、数据资源管理系统、事项管理系统、电子证照管理系统、一窗式行政审批系统、跨层级审批系统、互联网+政务服务微信平台、数据交叉验证及保全服务、短信服务子系统、监督管理系统、绩效考评系统、领导决策分析系统、移动审批办公平台、投资项目库、智能审批系统、即时交流系统、智能表单系统、政务信用管理系统、关于优化营商环境7个重点指标应用设计等构成。",
          src: require("../../assets/imgs/anli/guigang.png"),
          kehu: "港南区人民政府",
          city: "广西港南区",
          type: 3,
          imgs: [
            { src: require("../../assets/imgs/anli/guigang.png") },
            { src: require("../../assets/imgs/anli/guigang-1.png") },
            { src: require("../../assets/imgs/anli/guigang-2.png") },
          ],
        },
        {
          title: "贵港市港北区智慧政务综合平台项目",
          text: "本项目建设内容由基础平台、基础服务组件、数据资源管理系统、事项管理系统、电子证照管理系统、一窗式行政审批系统、跨层级审批系统、互联网+政务服务微信平台、数据交叉验证及保全服务、短信服务子系统、监督管理系统、绩效考评系统、领导决策分析系统、移动审批办公平台、投资项目库、智能审批系统、即时交流系统、智能表单系统、政务信用管理系统、关于优化营商环境7个重点指标应用设计等构成。",
          src: require("../../assets/imgs/anli/guigang.png"),
          kehu: "港北区人民政府",
          city: "广西港北区",
          type: 3,
          imgs: [
            { src: require("../../assets/imgs/anli/guigang.png") },
            { src: require("../../assets/imgs/anli/guigang-1.png") },
            { src: require("../../assets/imgs/anli/guigang-2.png") },
          ],
        },
        {
          title: "贵港市智慧政务综合平台二期项目",
          text: "本项目建设内容由基础平台、基础服务组件、数据资源管理系统、事项管理系统、电子证照管理系统、一窗式行政审批系统、跨层级审批系统、互联网+政务服务微信平台、数据交叉验证及保全服务、短信服务子系统、监督管理系统、绩效考评系统、领导决策分析系统、移动审批办公平台、投资项目库、智能审批系统、即时交流系统、智能表单系统、政务信用管理系统、关于优化营商环境7个重点指标应用设计等构成。",
          src: require("../../assets/imgs/anli/guigang.png"),
          kehu: "贵港市人民政府 ",
          city: "广西贵港市",
          type: 3,
          imgs: [
            { src: require("../../assets/imgs/anli/guigang.png") },
            { src: require("../../assets/imgs/anli/guigang-1.png") },
            { src: require("../../assets/imgs/anli/guigang-2.png") },
          ],
        },

        // {
        //   title: "原州区2019年农村饮水安全巩固提升工程施工8标段",
        //   text: "本工程建设内容由网络通信系统、泵站水厂监控系统、蓄水池监控系统、官网监测系统、入户智能计量系统、水质监测系统、原有平台扩容等构成。",
        //   src: require("../../assets/imgs/anli/test.png"),
        //   kehu: "原州区水利局",
        //   city: "宁夏原州区",
        //   type: 0,
        // },
        // {
        //   title: "成都市市场主体信用积分管理平台建设项目（二次）",
        //   text: "本工程建设内容由成都市公共信用信息系统（成都信用网）适应性改造、市场主体信用主题库建设、市场主体信用积分模型（双模型）建设、信用积分数据标准及治理、市场主体信用积分管理应用平台、信用积分用信API、信用风险监控建设、信用积分可视化等构成。",
        //   src: require("../../assets/imgs/anli/test.png"),
        //   kehu: "成都市工商局",
        //   city: "四川省成都市",
        //   type: 2,
        // },
        // {
        //   title:
        //     "四川新津白鹤滩国家湿地公园科普宣教系统建设项目设计-施工总承包（EPC)第二次",
        //   text: "本项目建设内容包括科普宣教馆陈列布展（含根据布展要求对房间进行重新规划布置和装修）、室内装饰装修工程（含游客服务中心、附属管理楼、次入口管理用房及两座观鸟塔装修）、信息化系统设施设备及附属软件开发（含区域地理遥感影像数据采集、分析、处理及三维建模展示等地理信息系统建设内容）、室外工程（含标识标牌、景观工程）等；我公司负责信息化系统设施设备及附属软件开发（含区域地理遥感影像数据采集、分析、处理及三维建模展示等地理信息系统建设内容）等工作。",
        //   src: require("../../assets/imgs/anli/test.png"),
        //   kehu: "新津水投",
        //   city: "四川省新津区",
        //   type: 0,
        // },
        // {
        //   title: "三环路扩能提升工程智能交通系统五标段外场通信传输子系统",
        //   text: "本工程建设内容由外场通信传输子系统和原三环路汇聚机箱拆除及利旧构成。",
        //   src: require("../../assets/imgs/anli/test.png"),
        //   kehu: "成都交投",
        //   city: "四川省成都市",
        //   type: 2,
        // },
        // {
        //   title:
        //     "玉龙雪山“一部手机游云南”智慧景区“管委会分控中心与备份数据机房改造项目”",
        //   text: "建设内容包括：将管委会现有数据机房改造为景区备份数据机房；将管委会现有监控室改造为分控中心；对现有信息中心办公室进行改造、管委会UPS电池间修建；分控中心、备份数据机房、信息中心办公室、UPS电池间装修等内容。",
        //   src: require("../../assets/imgs/anli/test.png"),
        //   kehu: "玉龙雪山景区管委会",
        //   city: "云南省丽江市",
        //   type: 2,
        // },
        {
          title: "贵港市智慧政务综合平台项目",
          text: "本工程建设内容由政务大数据、互联网政务服务门户平台、知识库、统一的诉求服务平台、一窗式行政审批系统、监督管理子系统、政务服务监督考核管理系统、智慧政务服务平台APP应用客户端系统、智慧政务服务微信平台、短信服务子系统、综合查询子系统、政务大数据领导决策平台、应用支撑管理系统、数据交叉验证及司法备案服务、领导决策支持系统、统一协同办公平台等构成。",
          src: require("../../assets/imgs/anli/guigang.png"),
          kehu: "贵港市人民政府",
          city: "广西贵港市",
          type: 3,
          imgs: [
            { src: require("../../assets/imgs/anli/guigang.png") },
            { src: require("../../assets/imgs/anli/guigang-1.png") },
            { src: require("../../assets/imgs/anli/guigang-2.png") },
          ],
        },
        // {
        //   title: "原州区2018年补充农村饮水安全巩固提升工程施工5标段",
        //   text: "本工程建设内容由城乡供水调度会商信息化监控中心建设、乡镇水利工作站硬件配置、供水管网自动化监控系统设备、末端计量等构成。",
        //   src: require("../../assets/imgs/anli/test.png"),
        //   kehu: "原州区水利局",
        //   city: "宁夏原州区",
        //   type: 0,
        // },

        {
          title: "六安城区报警与视频监控系统建设采购项目",
          text: "本工程建设内容由前端设备、市局监控中心设备、金安、开发区分局监控中心设备、裕安分局监控中心设备、派出所监控中心设备、社会监控资源接入、边界接入平台、无线图像传输系统、卡口和电子警察、卡口前端、电警前端、平台软件、项目运行维护等构成。",
          src: require("../../assets/imgs/anli/liuan.png"),
          kehu: "六安市公安局",
          city: "安徽省六安市",
          type: 1,
          imgs: [{ src: require("../../assets/imgs/anli/liuan.png") }],
        },

        // {
        //   title: "峨边县国土资源应急能力建设项目（信息化建设）施工",
        //   text: "本工程建设内容由前端监测系统、应急指挥中心、移动应急指挥、预警系统、数据库建设等构成。",
        //   src: require("../../assets/imgs/anli/test.png"),
        //   kehu: "峨边县国土资源局",
        //   city: "四川省峨边县",
        //   type: 1,
        // },

        // {
        //   title: "峨眉山市地质灾害监测预警工程建设项目",
        //   text: "本工程建设内容由前端监测系统、预警系统、保密系统、网络存储、应用系统、应用支撑平台、机房及会商系统构成。",
        //   src: require("../../assets/imgs/anli/test.png"),
        //   kehu: "峨眉山市国土局",
        //   city: "四川省峨眉山市",
        //   type: 1,
        // },
        // {
        //   title: "四川省地质灾害应急指挥地理信息系统项目",
        //   text: "本工程建设内容由地质灾害应急指挥地理信息系统构成。",
        //   src: require("../../assets/imgs/anli/test.png"),
        //   kehu: "四川省国土厅",
        //   city: "四川省",
        //   type: 1,
        // },
        // {
        //   title: "四川省地质灾害与气象数据实时共享系统建设项目",
        //   text: "本工程建设内容由地质灾害应急指挥地理信息系统构成。",
        //   src: require("../../assets/imgs/anli/test.png"),
        //   kehu: "四川省国土厅",
        //   city: "四川省",
        //   type: 1,
        // },
      ],
      nav: [
        { name: "智慧水务项目", type: 0, activebool: false },
        { name: "城市安全项目", type: 1, activebool: false },
        { name: "智慧城市项目", type: 2, activebool: false },
        { name: "智慧政务项目", type: 3, activebool: false },
      ],
      timer: null,
      arr: [],
      xidingarr: [],

      newArr: [],
      total: 0,

      shuiwu: [],
      anquan: [],
      chengshi: [],
      zhengwu: [],

      currentPage: 1,
    };
  },
  methods: {
    change(item) {
      this.type = item.type;
      if (this.type == 0) {
        this.page();
      } else if (this.type == 1) {
        this.page(1, this.anquan);
      } else if (this.type == 2) {
        this.page(1, this.chengshi);
      } else if (this.type == 3) {
        this.page(1, this.zhengwu);
      }
      document.body.scrollTop = 480;
      this.currentPage = 1;
    },
    goto(item) {
      // console.log("item :>> ", item);
      this.$router.push({ name: "xiangqing", params: item });

      window.sessionStorage.setItem("anli", JSON.stringify(item));
      // this.$router.push("/xiangqing");
    },
    up() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      let sudu = 40;
      if (top >= 0 && top < 1000) {
        sudu = 40;
      } else if (top >= 1000 && top < 2000) {
        sudu = 60;
      } else if (top >= 2000 && top < 3000) {
        sudu = 100;
      } else if (top >= 3000 && top < 4000) {
        sudu = 140;
      } else {
        sudu = 200;
      }
      this.timer = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            sudu;

        if (top <= 0) {
          clearInterval(this.timer);
        }
      }, 10);

      // document.body.scrollTop = 0;
    },
    scroll() {
      let scroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      this.arr.push(scroll);
      //console.log("scroll :>> ", scroll);
      if (this.arr.length > 2) {
        let a = [this.arr[this.arr.length - 1]];
        let b = [this.arr[this.arr.length - 2]];

        if (a - b >= 0) {
          // console.log("往下滑");

          if (scroll >= 470) {
            this.show = true;
          } else {
            this.show = false;
          }
        } else {
          // console.log("往上滑");
        }
        if (scroll > 120) {
          this.$refs.toback.style.opacity = "1";
        } else {
          this.$refs.toback.style.opacity = "0";
        }
      }
    },
    xidingscroll() {
      let scroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      this.xidingarr.push(scroll);

      if (this.xidingarr.length > 2) {
        let a = [this.xidingarr[this.xidingarr.length - 1]];
        let b = [this.xidingarr[this.xidingarr.length - 2]];

        if (a - b >= 0) {
          // console.log("往下滑");
          if (scroll >= 480) {
            this.$refs.navbox.style.backgroundColor = "#fff";
            this.$refs.navbox.style.position = "fixed";
            this.$refs.navbox.style.top = "0";
            this.$refs.navbox.style.left = "0";
            this.$refs.navbox.style.zIndex = "10";
            // this.$refs.nav.style.backgroundColor = "rgba(22, 100, 255, 1)";
          }
        } else {
          // console.log("往上滑");
          if (scroll < 480) {
            this.$refs.navbox.style.backgroundColor = "";
            this.$refs.navbox.style.position = "absolute";
            this.$refs.navbox.style.top = "24.6vw";
          }
        }
      }
    },

    pageChange(index) {
      let arr = null;
      if (this.type == 0) {
        arr = this.shuiw;
      } else if (this.type == 1) {
        arr = this.anquan;
      } else if (this.type == 2) {
        arr = this.chengshi;
      } else if (this.type == 3) {
        arr = this.zhengwu;
      }

      this.page(index, arr);
      document.body.scrollTop = 480;
    },

    //截取对应分类的项目
    page(index = 1, arr = this.shuiwu) {
      // console.log("index :>> ", index);
      let num = Math.ceil(arr.length / 5); //总页数
      this.total = num * 10;
      if (num >= index) {
        index = index * 5;
        let star = -5 + index;
        let end = 0 + index;
        this.newArr = arr.slice(star, end); //截取每页内容
      }
    },
  },

  mounted() {
    //判断当前是什么类型的项目
    for (const item of this.list) {
      if (item.type == 0) {
        this.shuiwu.push(item);
      } else if (item.type == 1) {
        this.anquan.push(item);
      } else if (item.type == 2) {
        this.chengshi.push(item);
      } else if (item.type == 3) {
        this.zhengwu.push(item);
      }
    }

    this.page();
    document.body.scrollTop = 0;
    window.addEventListener("scroll", this.scroll, true);
    window.addEventListener("scroll", this.xidingscroll, true);
  },
  beforeDestroy() {
    // this.time=null
    window.removeEventListener("scroll", this.scroll, true);
    window.removeEventListener("scroll", this.xidingscroll, true);
  },
};
</script>

<style lang="scss" scoped>
.chenggong {
  width: 1920px;
  height: 100%;
  position: relative;

  .toubu {
    width: 100%;
    height: 500px;
    background-image: url("../../assets/imgs/chanping/chanpingbg.png");
    background-size: 100% 100%;
    margin: 0 auto;
    position: relative;

    .text {
      // min-width: 462px;
      position: absolute;
      top: 180px;
      left: 343px;
      font-size: 60px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 84px;
      color: rgba(51, 51, 51, 1);
      // border-bottom: 1px solid rgba(75, 75, 75, 1);
      // padding-bottom: 20px;
      .bottom {
        font-size: 20px;
        font-family: Alibaba PuHuiTi Xi !important;
        font-weight: normal;
        line-height: 22px;
        color: rgba(51, 51, 51, 0.8);
        margin-top: 8px;
      }
    }
  }

  .nav-box {
    width: 100%;
    height: 52px;

    position: absolute;
    left: 0;
    top: 478px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    .nav {
      width: 380 * 3px;
      height: 52px;
      background: rgba(255, 255, 255, 1);

      display: flex;
      // position: absolute;
      // left: 390px;
      // top: 478px;

      .nav-son {
        width: 380px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-family: Alibaba PuHuiTi Xi !important;
        font-weight: normal;
        color: #888888;
        opacity: 1;
        cursor: pointer;
      }
      .nav-son:hover {
        color: #1664ff;
      }
      .nav-active {
        background-color: #1664ff;
        color: #fff !important;
      }
    }
  }
  .box {
    width: 100%;
    padding: 86px 280px;
    position: relative;
    background-color: rgba(243, 243, 243, 1);
    .son {
      width: 100%;
      height: 368px;
      display: flex;
      justify-content: space-between;
      position: relative;
      margin-bottom: 70px;
      .left {
        width: 590px;

        .title {
          font-size: 28px;
          font-family: Alibaba PuHuiTi;
          font-weight: normal;
          line-height: 40px;
          color: #333333;
          position: relative;
          top: 20px;
          transition: all 0.3s linear;
        }
        .text {
          width: 100%;
          height: 154px;

          padding-right: 30px;
          margin-top: 52px;
          font-size: 18px;
          font-family: Alibaba PuHuiTi Xi !important;
          font-weight: normal;
          line-height: 24px;
          color: #888888;
          position: relative;
          top: -20px;
          transition: all 0.3s linear;
        }
        .line {
          width: 0%;
          height: 1px;
          background-color: rgba(51, 51, 51, 1);
          transition: all 0.6s linear;
        }
        .gengduo {
          width: 128px;
          height: 44px;
          border: 1px solid rgba(204, 204, 204, 1);
          opacity: 1;
          font-size: 12px;
          font-family: Alibaba PuHuiTi Xi !important;
          font-weight: 400;

          color: #cccccc;
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: space-around;
          padding: 0 20px;
          position: absolute;
          bottom: 30px;
          left: 0;
          cursor: pointer;
          transition: all 0.3s linear;
        }
        .gengduo:hover {
          border: 1px solid #333333;

          color: #333333;
        }
      }
      .right {
        width: 664px;
        height: 368px;
        display: flex;
        align-items: center;
        justify-content: center;
        .img {
          // width: 664px;
          // height: 368px;
          width: 632px;
          height: 350px;
          transition: all 0.2s linear;
        }
      }
    }
    .son:hover .right .img {
      width: 664px;
      height: 368px;
    }
    .son:hover .left .line {
      width: 100%;
    }
    .son:hover .left .title {
      top: 0;
    }
    .son:hover .left .text {
      top: 0;
    }
    .son:hover .left .gengduo {
      bottom: 0;
    }
  }
}
</style>
